<script>

export default {
  name: 'PlayerFooter',

};
</script>
<style lang="scss" scoped>

</style>
<template>
  <div>
    (( footer ))
  </div>
</template>
