var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"player-app player-container"},[((!_vm.player))?_c('div',{staticClass:"player-overlay"},[((!_vm.player))?_c('div',{staticClass:"initialising"},[_vm._m(0)]):_vm._e()]):_vm._e(),_vm._v(" "),_c('header',[_c('div',{staticClass:"player-current-item"},[(_vm.player_current_media)?_c('div',{key:_vm.player_current_media.content.uuid},[_c('div',{staticClass:"primary-content"},[_c('div',{staticClass:"meta"},[_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.visit(_vm.player_current_media.content)}}},[_vm._v(_vm._s(_vm.player_current_media.content.name))]),_vm._v(" "),_c('br'),_vm._v(" "),_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.visit(_vm.player_current_media.content, 'artist')}}},[_vm._v(_vm._s(_vm.player_current_media.content.artist_display))]),_vm._v(" "),_c('br'),_vm._v(" "),_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.visit(_vm.player_current_media.content, 'release')}}},[_vm._v(_vm._s(_vm.player_current_media.content.release_display))])]),_vm._v(" "),_c('div',{staticClass:"visual"},[_c('img',{attrs:{"src":_vm.player_current_media.content.image}})])]),_vm._v(" "),_c('div',{staticClass:"plahead"},[_c('waveform',{key:_vm.player_current_media.uuid,attrs:{"item":_vm.player_current_media},on:{"seek":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.player_controls.apply(void 0, [ 'seek' ].concat( argsArray ))}}})],1)]):_c('div',{staticClass:"player-current-item-loading"})]),_vm._v(" "),_c('div',{staticClass:"player-controls"},[_c('div',{staticClass:"button-panel"},[_c('a',{on:{"click":function($event){$event.preventDefault();return _vm.player_play_offset(-1, _vm.player_current_media)}}},[_c('i',{staticClass:"fa fa-step-backward"})]),_vm._v(" "),_c('a',{on:{"click":function($event){$event.preventDefault();return _vm.player_controls('pause')}}},[_c('i',{staticClass:"fa fa-pause"})]),_vm._v(" "),_c('a',{on:{"click":function($event){$event.preventDefault();return _vm.player_controls('play')}}},[_c('i',{staticClass:"fa fa-play"})]),_vm._v(" "),_c('a',{on:{"click":function($event){$event.preventDefault();return _vm.player_play_offset(1, _vm.player_current_media)}}},[_c('i',{staticClass:"fa fa-step-forward"})])])])]),_vm._v(" "),_c('main',[_c('div',{staticClass:"player-content"},[((_vm.itemsToPlay && _vm.can_autoplay ))?_c('div',{staticClass:"items-to-play"},_vm._l((_vm.itemsToPlay),function(item_to_play){return _c('div',{key:item_to_play.uuid},[_c('transition',{attrs:{"name":"fade"}},[_c('div',{staticClass:"item-to-play"},[_c('div',{staticClass:"header"},[_c('span',{staticClass:"name"},[_vm._v(_vm._s(item_to_play.name))])]),_vm._v(" "),_vm._l((item_to_play.items),function(item){return _c('media',{key:item.key,attrs:{"item":item},on:{"play":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.player_controls.apply(void 0, [ 'play' ].concat( argsArray ))},"pause":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.player_controls.apply(void 0, [ 'pause' ].concat( argsArray ))},"seek":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.player_controls.apply(void 0, [ 'seek' ].concat( argsArray ))},"remove":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.player_controls.apply(void 0, [ 'remove' ].concat( argsArray ))},"visit":function($event){return _vm.visit.apply(void 0, arguments)}}})})],2)])],1)}),0):_vm._e(),_vm._v(" "),_c('transition',{attrs:{"name":"fade"}},[((! _vm.can_autoplay))?_c('div',{staticClass:"autoplay-container"},[_c('div',{staticClass:"autoplay-panel",on:{"click":_vm.player_resume_blocked_autoplay}},[_c('span',{staticClass:"autoplay-button",on:{"click":_vm.player_resume_blocked_autoplay}},[_vm._v("\n              Click to play\n            ")])]),_vm._v(" "),_c('div',{staticClass:"autoplay-info"},[_c('p',[_vm._v("\n              Your browser prevents audio from automatically playing."),_c('br'),_vm._v("\n              When opening the Open Broadcast player for the first time you have\n              to manually start the playback.\n            ")])])]):_vm._e()]),_vm._v(" "),_c('transition',{attrs:{"name":"fade"}},[(_vm.loading)?_c('div',{staticClass:"loading-container"},[_c('span',{staticClass:"loading-info"},[_c('loader')],1)]):_vm._e()])],1)]),_vm._v(" "),_c('footer',[_c('playerfooter',{attrs:{"items-to-play":_vm.itemsToPlay}})],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_vm._v("Initialising player."),_c('br'),_vm._v("Please be patient.")])}]

export { render, staticRenderFns }