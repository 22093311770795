<script>

export default {
  name: 'PlayerToolbar',

};
</script>
<style lang="scss" scoped>

</style>
<template>
  <div>
    (( toolbar ))
  </div>
</template>
